import React, { useState, useEffect } from 'react';
import Modal from '../Modal/index.js';
import { useDispatch } from 'react-redux';
import { getPhone } from '../../../redux/auth/operations.js';
import { showErrorToast, showSuccessToast } from 'utils/showToast';
import ImageAnimation3 from 'components/layout/loader/ImageAnimation3.jsx';
import {
  Box,
  CancelButton,
  DetailModalInput,
  DetailModalInputPlace,
  DetailModalInputWrap,
  DetailModalLabel,
  SaveButton,
  Text,
  Title,
  WrapButton,
} from './PhoneCall.styled.js';

const PhoneCall = ({ onClose, phone }) => {
  const dispatch = useDispatch();
  const [organizationPhone, setOrganizationPhone] = useState(`+380${phone.slice(-9)}` || '');
  const [organizationPlace, setOrganizationPlace] = useState(''.padStart(13-organizationPhone.length, '0'));
  const [isDisabled, setIsDisabled] = useState(true);
  const [load, setLoad] = useState(false);

  // Перевірка валідності номера телефону
  const isPhoneValid = (phone) => {
    const phoneRegex = /^\+380\d{9}$/;// Регулярний вираз для перевірки номера
    return phoneRegex.test(phone);
  };

  // Перевіряємо початкове значення при першому рендері
  useEffect(() => {
    setIsDisabled(!isPhoneValid(organizationPhone));
  }, [organizationPhone]);

  // Обробник зміни номера телефону
  const handlePhoneChange = (e) => {
    const value = e.target.value;
    if (value.length > 13) return;
    const formattedPhone = value.length < 4 ? '+380' : `+380${value.slice(4)}`;
    setOrganizationPlace(''.padStart(13 - formattedPhone.length, '0'));
    setOrganizationPhone(formattedPhone);
    setIsDisabled(!isPhoneValid(formattedPhone)); // Оновлення стану кнопки залежно від валідності номера
  };
  const handlePhone = async () => {
    if (isDisabled) return;

    setLoad(true);
    const formattedPhone = organizationPhone.slice(1);
    try {
      const response = await dispatch(getPhone({ phone: formattedPhone })).unwrap();

      if (response.status === 200) {
        // Виводимо успішне повідомлення
        showSuccessToast(response.message);
        onClose(); // Закриваємо модальне вікно
      } else {
        showErrorToast('Щось пішло не так. Спробуйте ще раз.');
      }
    } catch (error) {
      console.error('Failed to request callback:', error);
      showErrorToast('Не вдалося виконати запит. Спробуйте ще раз.');
    } finally {
      setLoad(false);
    }
  };

  return (
    <Modal onClose={onClose}>
      <Box>
        <Title>Замовити дзвінок</Title>
        <Text>
          Для того, щоб ми могли зв’язатися з Вами, будь ласка, введіть номер
          телефону, на який хочете отримати дзвінок.
        </Text>
        <DetailModalLabel htmlFor="organizationPhone">Номер телефону</DetailModalLabel>
       <DetailModalInputWrap>
        <DetailModalInput
          id="organizationPhone"
          name="organizationPhone"
          placeholder="Введіть номер телефону"
          value={organizationPhone}
          onChange={handlePhoneChange}
        />
          <DetailModalInputPlace>{organizationPlace}</DetailModalInputPlace>
        </DetailModalInputWrap>
        <WrapButton>
          <CancelButton onClick={onClose}>Скасувати</CancelButton>
          {load ? (
            <ImageAnimation3 />
          ) : (
            <SaveButton onClick={handlePhone} disabled={isDisabled}>
              Підтвердити
            </SaveButton>
          )}
        </WrapButton>
      </Box>
    </Modal>
  );
};

export default PhoneCall;
