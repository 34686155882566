import React, { useEffect, useState } from 'react';
import { Box, Img, NoButton, Text, Title, WrapButton, YesButton } from './OnboardBody.styled';
import Image from '../../../../images/onBoard.png'
import Image1 from '../../../../images/FullAcses.png'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PhoneCall from 'components/modal/PhoneCall/PhoneCall';
import { selectUser } from '../../../../redux/auth/selectors';
import { useSelector } from 'react-redux';
import { format, } from "date-fns";
import { uk } from 'date-fns/locale';

const OnboardBody = ({ onClose, type, onOpen }) => {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isModalOpen2, setModalOpen2] = useState(false);
  const user = useSelector(selectUser);
   useEffect(() => {
      const handleKeyDown = event => {
        if (event.key === 'Escape') {
          handleCloseModal2();
        }
      };
  
      if (isModalOpen2) {
        window.addEventListener('keydown', handleKeyDown);
      }
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }, [isModalOpen2]);
  const handleCloseModal2 = () => {
    setModalOpen2(false);
    document.body.style.overflow = 'auto';
  }
  return (
    <Box>
      <Img src={type === 'start' ? Image : Image1} alt="Довідка" />
      <Title>{type === 'start' ? 'Ласкаво просимо до Вашого кабінету STRAVA PORUCH' : 'Доступ до повного функціоналу'}</Title>
      <Text >{type === 'start' ? 'Ми підготували для Вас невеликий ознайомчий тур по нашому сервісу' : user?.merchant?.expires_at ? `До ${format(new Date(user.merchant?.expires_at), "d MMMM yyyy 'року'", { locale: uk })} ви будете мати доступ до всього функціоналу та можете продавати свої диво-кошики безкоштовно! Якщо у вас є питання – замовте дзвінок, і наш менеджер допоможе вам.` :'Щоб мати можливість продавати свої диво-кошики – сплатіть річний внесок. Сформуйте рахунок самостійно або замовте дзвінок і наш менеджер відповість на Ваші запитання та надішле рахунок на пошту.'}</Text>
      <WrapButton className={type}>
        <YesButton className={user?.merchant?.expires_at && type !== 'start'&&'green'} type={type} onClick={() => { onClose(); if (type !== 'start') { setModalOpen2(true) } }  }>{type === 'start' ? 'Пропустити' : 'Замовити дзвінок'}</YesButton>
        {!user?.merchant?.expires_at || type === 'start'&&
 <NoButton type={type}
          onClick={() => {
            if (type === 'start') {
              navigate('/main?onboard=1')
              onClose();
              document.body.style.overflow = 'auto';
            } else {

          
              onClose();
                if  (user.merchant.billingPlan.amount===0) onOpen();
            }
          }}>{type === 'start' ? 'Розпочати' : 'Сформувати рахунок'}</NoButton>}

      </WrapButton>
      {isModalOpen2 && (
        <PhoneCall onClose={handleCloseModal2} phone={user?.phone} />
      )}
    </Box>
  );
};

export default OnboardBody;