import styled from '@emotion/styled';
import { CgClose } from 'react-icons/cg';

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background:rgba(29, 29, 57, 0.5); ;

  display: flex;
  justify-content: center;

  align-items: center;
  z-index: 10;
`;

export const ModalContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: space-between;
  flex-direction: column;
max-height:calc(100vh - 80px);
/* overflow-y:auto; */
${props => props.pad === "false" ? "max-width:426px;" : null}
  border-radius: 8px;
  box-shadow: 0 0 40px 0 rgba(195, 195, 195, 0.25);
  background:var(--colors-grey-25);
  padding: ${props => props.pad === "false" ? "0" : "28px 20px"};
  position: relative;
  z-index: 1100;
  /* overflow: hidden; */

/* 
  @media screen and (max-width: 374px) {
    max-width: 335px;
    min-height: 290px;
  }

  @media screen and (min-width: 375px) and (max-width: 767px) {
    min-width: 335px;
    min-height: 290px;
  }

  @media screen and (min-width: 767px) {
    padding: 32px;
    min-width: 628px;
 
  } */
`;

export const CloseBtn = styled(CgClose)`
  position: absolute;
  top: 14px;
  right: 14px;
  width: 24px;
  height: 24px;
  
  color: var(--colors-grey-400);
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;

  :hover {
    color: var(--accent);
  }

  @media (min-width: 767px) {
    top: 18px;
    right: 18px;
  }
`;
