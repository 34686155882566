import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { url } from 'components/other/terms';

export const calendarApi = createApi({
  reducerPath: 'calendar',

  baseQuery: fetchBaseQuery({
    baseUrl: url+'/calendar',
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),

  tagTypes: ['Calendar'],

  endpoints: builder => ({
    getCalendar: builder.query({
      query: (month) => ({
        url: `/month?month=${month}`,
        method: 'GET',
      }),
      providesTags: ['Calendar'],
    }),
    getRegular: builder.query({
      query: () => ({
        url: `/scheduled`,
        method: 'GET',
      }),
      providesTags: ['Calendar'],
    }),
    editWorkDay: builder.mutation({
      query: ({ date, state }) => ({
        url: `/toggle-state?date=${date}&state=${state}`,
        method: 'POST',
      }),
      invalidatesTags: ['Calendar'],
    })
  }),
});


export const {
  useGetCalendarQuery,
  useGetRegularQuery,
  useEditWorkDayMutation
} = calendarApi;
