import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { url } from 'components/other/terms';

export const documentsApi = createApi({
  reducerPath: 'documents',

  baseQuery: fetchBaseQuery({
    baseUrl: url+'/documents',
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),

  tagTypes: ['Documents'],

  endpoints: builder => ({

    getDocument: builder.query({
      query: (id) => ({
        url: `/list`,
        method: 'GET',
      }),
      providesTags: ['Documents'],
    }),
    viewDocument: builder.query({
      query: (id) => ({
        url: `/view?id=${id}`,
        method: 'GET',
      }),
      providesTags: ['Documents'],
    }),
    downloadDocument: builder.query({
      query: (id) => ({
        url: `/download?id=${id}`,
        method: 'GET',
        responseHandler: (response) => response.blob(), // Обробка бінарних даних
      }),
      transformResponse: (response) => response,
      providesTags: ['Documents'],
    }),


  }),
});

export const {
  useViewDocumentQuery,
  useDownloadDocumentQuery,
useGetDocumentQuery
} = documentsApi;
