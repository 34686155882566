import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { ReactComponent as InfoIcon } from '../../../images/circle.svg';
import { ReactComponent as QuestIcon } from '../../../images/circleQues.svg';
import { ReactComponent as EyeIcon } from '../../../images/eye.svg';
import { ReactComponent as DownloadIcon } from '../../../images/download2.svg';

export const IconInfo = styled(InfoIcon)`
  width: 16px;
  height: 17px;
cursor:pointer;
 
`;

export const IconQuest = styled(QuestIcon)`
  width: 20px;
  height: 20px;
cursor:pointer;
 /* margin-left: 8px; */
`;
export const ButtonWrap = styled.div`

display: flex;
align-items: center;
justify-content: space-between;
gap:12px;
width:100%;


`;
export const ModalTitle = styled.h2`
font-family: var(--font-family);
font-weight: 600;
font-size: 20px;
line-height: 135%;
text-align: center;
color: var(--colors-grey-900);
width: 604px;
margin-bottom: 4px;
`;

export const ModalInfoText = styled.p`
font-family: var(--font-family);
font-weight: 500;
font-size: 16px;
line-height: 137%;
text-align: center;
color: var(--colors-grey-600);
width: 604px;
`;


export const ModalAmountBox = styled.div`
background: var(--colors-primary-50);
border: 1px solid var(--colors-primary-600);
border-radius: 16px;
padding: 16px;
display: flex;
justify-content: space-between;
align-items: center;
width: 604px;
margin: 24px 0 0 0;
`;


export const ModalAmountText = styled.p`
font-family: var(--font-family);
font-weight: 500;
font-size: 14px;
line-height: 150%;
color: var(--colors-grey-900);
`;
export const ModalAmountValue = styled.p`
font-family: var(--font-family);
font-weight: 600;
font-size: 24px;
line-height: 125%;
text-align: center;
color: var(--colors-primary-600);
`;


export const CancelButton = styled.button`
font-family: var(--font-family);
font-weight: 600;
font-size: 16px;
line-height: 137%;
text-align: center;
width:calc((100% - 12px) / 2);

display: flex;
align-items: center;
color: var(--colors-primary-600);
background:none;
border: 1px solid var(--colors-primary-600);
border-radius: 360px;
padding: 8px 12px;
justify-content: center;
height: 48px;
`;

export const SaveButton = styled.button`
font-family: var(--font-family);
font-weight: 600;
font-size: 16px;
line-height: 137%;
text-align: center;
color: var(--colors-grey-25);
display: flex;
justify-content: center;
width:calc((100% - 12px) / 2);
align-items: center;
background:${props => props.disabled ? 'var(--colors-grey-200)' : 'var(--colors-primary-600)'};
border-radius: 360px;
padding: 8px 12px;
height: 48px;

`;





export const DetailModalBox = styled.ul`
background: none;
width: 604px;
height: fit-content;
margin: 24px 0;
margin-bottom: 8px;
display: flex;
flex-direction: column;
gap:16px;
`;
export const DetailModalItem = styled.li`

width: 100%;

display: flex;
cursor: pointer;
flex-direction: column;
gap:8px;
`;

export const DetailModalLabel = styled.label`
font-family: var(--font-family);
font-weight: 600;
font-size: 14px;
line-height: 150%;
color: var(--colors-grey-800);
width: 100%;
display: flex;align-items: center;
gap: 8px;
`;

export const DetailModalInput = styled.input`
appearance: none;
outline: none;
border: 1px solid var(--colors-grey-100);
border-radius: 360px;
padding: 8px 16px;
width: 604px;
height: 48px;
background: var(--colors-grey-25);
font-family: var(--font-family);
font-weight: 500;
font-size: 16px;
line-height: 137%;
color: var(--colors-grey-900);
&:hover{
    outline:1px solid var(--colors-primary-600);
}
&::placeholder{
   font-family: var(--font-family);
font-weight: 500;
font-size: 16px;
line-height: 137%;
color: var(--colors-grey-400); 
}
`;

export const LinksContainer = styled.nav`
display: flex;
justify-content: center;
flex-wrap: wrap;
align-items: center;
margin-bottom: 24px;
gap: 18px;
width: 100%;
`;

export const CheckBoxWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background:none;
    border: 1px solid var(--colors-grey-400);
    border-radius: 2px;
  }

  input:checked + .checkmark {
    background-color: var(--colors-secondary-500);
    border: 1px solid var(--colors-secondary-500);
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked + .checkmark:after {
    display: block;
  }

  .checkmark:after {
    top: 10%;
    left: 30%;
   
    width: 8px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`;

export const Label1 = styled.label`
font-family: var(--font-family);
font-weight: 500;
font-size: 14px;
line-height: 150%;
color: var(--colors-grey-900);
display: flex;
gap:4px;

`;
export const Label2 = styled.label`
font-family: var(--font-family);
font-weight: 500;
font-size: 14px;
line-height: 150%;

text-decoration-skip-ink: none;
text-align: right;
color: var(--colors-grey-600);
display: flex;
flex-direction:row;
gap:4px;
align-items: center;

`;
export const LinkTo = styled.p`

margin-top: 12px;
  color: var(--colors-grey-900);
font-size: 14px;
font-weight: 600;
line-height: 24px;
letter-spacing: 0%;
text-align: center;
  cursor: pointer;
align-self: flex-end;
  &:hover {
    text-decoration: underline;
  }

  @media screen and (min-width: 768px) {
    font-size: 16px;
  }
`;

export const LinkToA = styled.a`


font-family: var(--font-family);
font-weight: 500;
font-size: 14px;
line-height: 150%;
text-decoration: underline;
text-decoration-skip-ink: none;
text-align: right;
color: var(--colors-grey-600);
  &:hover {
    text-decoration: underline;
  }

  @media screen and (min-width: 768px) {
    font-size: 16px;
  }
`;
export const Box = styled.div`


  width: 386px;


background: none;
appearance: none;



`;



export const Img = styled.img`
margin-top: 10px;
object-fit: contain;
border-radius: 40px;

width: 386px;
height: 184px;
margin-bottom: 16px;
`;
export const Title = styled.h2`
font-family: var(--font-family);
font-weight: 600;
font-size: 20px;
line-height: 135%;
text-align: center;
color: var(--colors-grey-900);
margin-bottom: 4px;
`;

export const Text = styled.p`
font-family: var(--font-family);
font-weight: 500;
font-size: 16px;
line-height: 137%;
text-align: center;
color: var(--colors-grey-600);
margin-bottom: 24px;
`;
export const WrapButton = styled.div`
display: flex;
flex-direction:column-reverse;
gap: 12px;
width: 100%;

`;

export const YesButton = styled.button`
border: 1px solid var(--colors-primary-600);
border-radius: 360px;
padding: 8px 12px;
width: 100%;
height: 48px;
display: flex;
align-items: center;
justify-content: center;
font-family: var(--font-family);
font-weight: 600;
font-size: 16px;
line-height: 137%;
text-align: center;
color: var(--colors-primary-600);
background: none;
`;

export const NoButton = styled.button`
border-radius: 360px;
padding: 8px 12px;
width: 100%;
height: 48px;
display: flex;
align-items: center;
justify-content: center;
background: var(--colors-primary-600);
font-family: var(--font-family);
font-weight: 600;
font-size: 16px;
line-height: 137%;
text-align: center;
color: var(--colors-grey-25);

`;
export const IconEye = styled(EyeIcon)`
  width: 24px;
  height: 24px;
  stroke: var(--colors-grey-25);
   margin-right: 8px;
  cursor: pointer;

`;
export const IconDownload = styled(DownloadIcon)`
 width: 24px;
  height: 24px;
  margin-right: 8px;
  cursor: pointer;
   fill: var(--colors-primary-600);
`;