import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { url } from 'components/other/terms';

export const shopsApi = createApi({
  reducerPath: 'shops',
  baseQuery: fetchBaseQuery({
    baseUrl: url+'/shops',
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: false,
  keepUnusedDataFor: 1,

  endpoints: builder => ({
    fetchShops: builder.query({
      query: () => '',
      providesTags: ['Shops'],
    }),
    createShop: builder.mutation({
      query: (data) => ({
        url: '/create',
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`, // Якщо токен зберігається в localStorage
        },
        params: {
          name: data.name,        // Передача name через параметри запиту (query params)
          address: data.address,  // Передача address через параметри запиту (query params)
        },
      }),
      invalidatesTags: ['Shops'],
    }),
    deleteShop: builder.mutation({
      query: id => ({
        url: `/delete?id=${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Shops'],
    }),
  }),
});
export const {
  useFetchShopsQuery,
  useCreateShopMutation,
  useDeleteShopMutation

} = shopsApi;
