import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { url } from 'components/other/terms';

export const financeApi = createApi({
  reducerPath: 'finance',

  baseQuery: fetchBaseQuery({
    baseUrl: url,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),

  tagTypes: ['Accruals'],

  endpoints: builder => ({
    getAccruals: builder.query({
      query: ({ page = 1, from, to } = {}) => {
        // Створюємо об'єкт параметрів запиту
        const params = {
          page, // додаємо параметр page до запиту
        };

        // Додаємо параметри 'from' та 'to', якщо вони задані
        if (from && to) {
          params.from = from;
          params.to = to;
        }


        return {
          url: `/accruals/`,
          method: 'GET',
          params, // передаємо параметри запиту
        };
      },
      transformResponse: (response, meta) => {
        const pagination = {
          currentPage: meta.response.headers.get('x-pagination-current-page'),
          pageCount: meta.response.headers.get('x-pagination-page-count'),
          perPage: meta.response.headers.get('x-pagination-per-page'),
          totalCount: meta.response.headers.get('x-pagination-total-count'),
        };
        return { data: response, pagination };
      },
      providesTags: ['Accruals'],
    }),
  }),
});

export const {
  useGetAccrualsQuery,
} = financeApi;