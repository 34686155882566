import React, { useEffect, useState } from 'react';
import Modal from '../Modal';
import { useGetDocumentQuery } from '../../../redux/documents/documentsApi';

import { Box, ButtonWrap, CancelButton, CheckBoxWrapper, DetailModalBox, DetailModalInput, DetailModalItem, DetailModalLabel, IconDownload, IconEye, IconInfo, IconQuest, Img, Label1, Label2, LinksContainer, LinkTo, LinkToA, ModalAmountBox, ModalAmountText, ModalAmountValue, ModalInfoText, ModalTitle, NoButton, SaveButton, Text, Title, WrapButton, YesButton } from './MerchantBill.styled.js';
import { useDispatch } from 'react-redux';
import { getMerchantBill, updateUser } from '../../../redux/auth/operations';
import convertHtmlToPdf from 'utils/function';
import DocsViewModal from '../DocsViewModal/DocsViewModal';
import Tooltip from 'components/other/Tooltip';
import ImageAnimation3 from 'components/layout/loader/ImageAnimation3';
import Image from '../../../images/success.png'
import { showErrorToast } from 'utils/showToast';


const MerchantBill = ({ onClose, merchant }) => {
  const dispatch = useDispatch();

  const { data: viewData, isLoading: isloadSuc } = useGetDocumentQuery();
  const [organizationName, setOrganizationName] = useState(merchant?.organizationName ? merchant?.organizationName : '');
  const [organizationCode, setOrganizationCode] = useState(merchant?.organizationCode ? merchant?.organizationCode : '');
  const [liqpay, setLiqpay] = useState(merchant?.portmone_id  ? merchant?.portmone_id : '');
  const [isDisabled, setIsDisabled,] = useState(organizationName && organizationCode && liqpay ? '' : 'true');
  const [isChecked, setIsChecked] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectItem, setSelectItem] = useState({});
  const [selectFilter, setSelectFilter] = useState('');
  const [selectFilter2, setSelectFilter2] = useState('');
  const [load, setLoad] = useState(false);
  const [mode, setMode] = useState("edit");
  const [file, setFile] = useState(null);

  const expires1 = new Date(merchant?.expires_at);
  const now = new Date();
  console.log('expires1 :>> ', expires1);
  useEffect(() => {

    if (organizationName && organizationCode && liqpay && isChecked === isDisabled)
      return
    else
      setIsDisabled(organizationName && organizationCode && liqpay && isChecked ? '' : 'true')
  },
    [organizationName, organizationCode, liqpay, isDisabled, isChecked])

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  }
  const handleGet = async () => {
    if (isDisabled) return;

    setLoad(true);
    try {
      if (
        organizationName !== merchant.organizationName ||
        organizationCode !== merchant.organizationCode ||
        liqpay !== merchant.portmone_id
      ) {
        await dispatch(updateUser({
          organizationName,
          organizationCode,
          portmone_id: liqpay
        }));
        console.log('Details successfully updated');
      }

      const downloadData = await dispatch(getMerchantBill());
      if (downloadData.payload instanceof Blob) {
        setFile(downloadData.payload);
        setMode('down');
      } else {
        showErrorToast('Файл не було завантажено');
      }
    } catch (error) {
      console.error('Failed to save details or download file:', error);
    } finally {
      setLoad(false);
    }
  };

  const handleDown = async () => {
  console.log('file :>> ', file);
      if (file ) {
        const blob = file;
        console.log('blob :>> ', blob);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'merchant-bill.pdf';
        link.click();
        window.URL.revokeObjectURL(url);
      } else {
        showErrorToast('Файл не було завантажено' );
      }

    
  
  };

  const handleView = async () => {
    if (file) {
      const blob = file;
      const url = window.URL.createObjectURL(blob);
      window.open(url, '_blank');
      window.URL.revokeObjectURL(url); // Опціонально: видалення об'єкта після використання
    } else {
      showErrorToast('Файл не було завантажено');
    }
  };




  const handleOpenModal = (filter, filter2) => {
    if (viewData) {
      const filteredData = viewData.filter(
        item => item.type === filter && item.appointment === filter2
      );
      setSelectItem(filteredData[0])
      setSelectFilter(filter);
      setSelectFilter2(filter2);
      setModalOpen(true);
      document.body.style.overflow = 'hidden';
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);

    document.body.style.overflow = 'auto';

  };

  // Обробка завантаження документа
  const handleDownloadPdf = () => {
    const filteredData = viewData.filter(
      item => item.type === selectFilter && item.appointment === selectFilter2
    );
    convertHtmlToPdf(filteredData[0]);
  };


  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'Escape') {
        handleCloseModal();
      }
    };

    if (isModalOpen) {
      window.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isModalOpen]);



  return (
    <Modal onClose={onClose}>
      {mode === 'edit' ?
        <>
      <ModalTitle>Додайте реквізити</ModalTitle>
      <ModalInfoText>Щоб сформувати рахунок, заповніть поля нижче. Будьте уважні, адже у випадку невірно введених даних, рахунок може бути недійсним.</ModalInfoText>
      <ModalAmountBox>
        <ModalAmountText>
          Сума річного внеску
        </ModalAmountText>
        <ModalAmountValue>
              {expires1 > now ? merchant?.billingPlan?.amount:1000} грн.
        </ModalAmountValue>
      </ModalAmountBox>
      <DetailModalBox>
        <DetailModalItem>
          <DetailModalLabel htmlFor='organizationName'>Назва мерчанта (юр. особи або ФОП)</DetailModalLabel>
          <DetailModalInput id="organizationName" name='organizationName' placeholder='Введіть назву мерчанта' value={organizationName} onChange={(e) => { setOrganizationName(e.target.value) }} />
        </DetailModalItem>
        <DetailModalItem>
          <DetailModalLabel htmlFor='organizationCode'>Код ЄДРПОУ (для юр. осіб) або РНОКПП (для ФОП)</DetailModalLabel>
          <DetailModalInput id="organizationCode" name='organizationCode' placeholder='Введіть код' value={organizationCode} onChange={(e) => { setOrganizationCode(e.target.value) }} />
        </DetailModalItem>
        <DetailModalItem>
          <DetailModalLabel htmlFor='liqpay'>Portmone ID  <Tooltip one={'one'} content="Для отримання оплат за продані диво-кошики, Вам необхідно мати Portmone ID в платіжному сервісі Portmone">
            <IconQuest />
          </Tooltip></DetailModalLabel>
          <DetailModalInput id="liqpay" name='liqpay' placeholder='Введіть ID' value={liqpay} onChange={(e) => { setLiqpay(e.target.value) }} />
        </DetailModalItem>
      </DetailModalBox>
      <LinksContainer style={{ justifyContent: 'end' }}>
        
            <Label2 htmlFor="confirm">Для отримання ID натисніть посилання<LinkToA href='https://business.portmone.com.ua/signup#stravaporuch' target="_blank" >www.portmone.com.ua</LinkToA></Label2>

      </LinksContainer>
      <LinksContainer style={{ justifyContent: 'flex-start' }}>
        <CheckBoxWrapper>
          <input type="checkbox" id="confirm" checked={isChecked}
            onChange={handleCheckboxChange} />
          <label htmlFor="confirm" className="checkmark"></label>
        </CheckBoxWrapper>
        <Label1 htmlFor="confirm">Я згоден (-на) з <LinkTo onClick={() => { handleOpenModal('oferta', 'all') }}  style={{ fontSize: '15px', marginTop: '0px', textDecoration: 'underline' }}>Публічною офертою</LinkTo></Label1>

      </LinksContainer>




      <ButtonWrap>
        <CancelButton onClick={onClose}>Скасувати</CancelButton>
        {load ? <ImageAnimation3/> : <SaveButton onClick={handleGet} disabled={isDisabled ? true : false}>Сформувати рахунок</SaveButton> }

      </ButtonWrap>
      {isModalOpen && (
        <DocsViewModal onClose={handleCloseModal} item={selectItem} handleDownloadPdf={handleDownloadPdf} />
          )}</>:
      <Box>
        <Img src={Image} alt="Видалення" />
          <Title>Рахунок успішно сформовано!</Title>
          <Text>Ви можете переглянути його або завантажити за допомогою кнопок нижче. </Text>
        <WrapButton>
            <YesButton onClick={handleDown}><IconDownload/> Завантажити</YesButton>
            <NoButton onClick={handleView}><IconEye/> Переглянути</NoButton>
        </WrapButton>
      </Box>
  }
    </Modal>
  );
};

export default MerchantBill;